import React from "react";
import "./Team.css";
import TeamMember from "./TeamMember";

const Team = () => {
  return (
    <section className="teamSection" id="team">
      <div className="explainer">
        <h1 className="meet">Meet The Team</h1>
        <div className="summary">
          <p>
            The core team consists of fully doxxed and internally doxxed
            members, covering a wide array of skillsets from marketing to web
            development to community building and everything inbetween. <br />
            <br />
            We are a 20 strong, very close team and are committed to changing
            the cryptocurrency space with our highly ambitious project, TacoCat.
          </p>
        </div>
      </div>
      <TeamMember name="Danny" />
      <TeamMember name="Jasper" />
      <TeamMember name="Alin" />
      <TeamMember name="Radek" />
      <TeamMember name="Kled" />
      <TeamMember name="Zach" />
    </section>
  );
};

export default Team;
