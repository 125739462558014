import DannyProfileImage from "./images/Danny-panama.jpeg";
import JasperProfileImage from "./images/Jasper.png";
import AlinProfileImage from "./images/Alin.jpeg";
import RadekProfileImage from "./images/Radek.jpeg";
import KledProfileImage from "./images/Kled.jpeg";
import ZachProfileImage from "./images/Zach.jpg";

const TeamInfo = {
  Danny: {
    realName: "Danny Mijac",
    role: "CEO",
    profileImage: DannyProfileImage,
    bio: "Hey there, I'm Danny, CEO, Angel Investor and co-founder of The TacoCat Company. My number one driving force is to help fully realize the incredible potential of this project. We're aiming to disrupt the crypto space and I couldn't be more excited to be working with the incredible team we have assembled. Watch this space.",
    socialLinks: {
      twitter: "https://twitter.com/ceo_taco",
      linkedin: "https://www.linkedin.com/in/daniel-mijac-a14597214/",
    },
  },
  Jasper: {
    realName: "Jasper van Ravenzwaaij",
    role: "COO",
    profileImage: JasperProfileImage,
    bio: "Hi, I'm the other proud co-founder of this project, a 32 year old from Netherlands living in Germany. I run the day to day operations within The TacoCat Company and make sure everything gets done. I have a pragmatic, hands-on mentality that originates from my conventional, potato-farmer upbringing. It serves me well in the fast-paced world of cryptocurrency.",
    socialLinks: {
      twitter: "https://twitter.com/coo_taco",
      linkedin: "https://www.linkedin.com/in/jaspervanravenzwaaij/",
    },
  },
  Alin: {
    realName: "Alin Ybarra",
    role: "CSO",
    profileImage: AlinProfileImage,
    bio: "Hello Tacocat Community! My name is Alin and I'm the Chief Strategy Officer at The Tacocat Company. For the last 23 years I've been primarily involved in Finance and Asset Management.  My greatest accomplishment has been helping tens of thousands of homeowners to keep their homes after the financial crisis.  My job is to help develop and nurture an ecosystem of utilities for our blockchain technology.",
    socialLinks: {
      twitter: "",
      linkedin: "",
    },
  },
  Radek: {
    realName: "Radek Lenczowski",
    role: "CCO",
    profileImage: RadekProfileImage,
    bio: "Call me Bobbert. As the CCO I’m in charge of communications and making sure everything is glued together. I enjoy a healthy combination of work, investing and tacos! I live and breathe TacoCat and love engaging with our community!",
    socialLinks: {
      twitter: "https://www.twitter.com/cco_taco",
      linkedin: "https://www.linkedin.com/in/radoslaw-lenczowski-001740214",
    },
  },
  Kled: {
    realName: "Khaled Mekki",
    role: "CBDO",
    profileImage: KledProfileImage,
    bio: "You can call me Kled. My job is to take the ambitious goals of this project and help them to become reality. My high level of education and extreme flexibility allows me to get this done effectively.",
    socialLinks: {
      twitter: "https://twitter.com/cbdo_taco",
      linkedin: "https://www.linkedin.com/in/khaled-mekki-6a989070/",
    },
  },
  Zach: {
    realName: "Zach Scott",
    role: "CTO",
    profileImage: ZachProfileImage,
    bio: "Hey Taco fam, I'm Zach, CTO of The TacoCat Company. I come from a Web Development, Information Systems, and Data Analytics background. My main goal is to work alongside our developers to bring cutting edge tech and innovation to the TacoCat project. Incredibly excited for the future of TacoCat.",
    socialLinks: {
      twitter: "https://twitter.com/CTO_taco",
      linkedin: "https://www.linkedin.com/in/z-scott/",
    },
  },
};

export default TeamInfo;
