import React from 'react';
import './Team.css';
import TeamInfo from './TeamInfo.js'

const TeamMember = ({ name }) => {
  const { realName, role, profileImage, bio, socialLinks } = TeamInfo[name];

  return (
    <>
      <div className="container">
        <img
          className="profileImage" 
          src={profileImage} 
          alt={name}>
        </img>
        <div className="profileContent">
          <h1 className="name">{realName}</h1>
          <p className="role">{role}</p>
          <p className="bio">{bio}</p>
          <div className="socialLinks">
            <ul className="socialsWrapper">
              { socialLinks.twitter && 
                <li className="social">
                  <a className="social-icon-link twitter" href={socialLinks.twitter} rel="noopener noreferrer" target="_blank" aria-label='Twitter'>
                    <i className='fab fa-twitter' />
                  </a>
                </li>
              }
              { socialLinks.linkedin &&
                <li className="social">
                  <a className="social-icon-link linkedin" href={socialLinks.linkedin} rel="noopener noreferrer" target="_blank" aria-label='LinkedIn'>
                      <i className='fab fa-linkedin' />
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamMember;