import React from "react";
import faqList from "./faqList";
import styled from "styled-components";

export default function FrequentlyAskedQuestions() {
  return (
    <StyledSection>
      <GutterContainer>
        <HeaderContainer>
          <StyledHeader>FAQ</StyledHeader>
        </HeaderContainer>
        <ContentContainer>
          <StyledOrderedList>
            {faqList.map((faqItem) => (
              <>
                <StyledListItem>{faqItem.question}</StyledListItem>
                <StyledParagraph>{faqItem.answer}</StyledParagraph>
              </>
            ))}
          </StyledOrderedList>
        </ContentContainer>
      </GutterContainer>
    </StyledSection>
  );
}

const StyledSection = styled.section`
  background-color: #fbebb9;
  min-height: 100vh;
  padding-top: 3em;
  padding-bottom: 3em;
`;

const HeaderContainer = styled.div`
  flex-grow: 2;
  text-align: center;
  margin-top: 1.4em;
  margin-bottom: 1.4em;
`;

const ContentContainer = styled.div`
  margin: 2em 4em 2em 4em;

  @media screen and (max-width: 500px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const StyledOrderedList = styled.ol`
  list-style: decimal inside;
`;

const StyledParagraph = styled.p`
  margin: 1.4em;
  line-height: 1.4em;
  font-size: 1.3em;
  font-family: "BrandonGrotesqueBlackLight";
`;

const GutterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const StyledListItem = styled.li`
  @media screen and (max-width: 500px) {
    margin: 7% auto;
    width: 82%;
  }

  font-family: "Bungee";
  line-height: 1.4em;
  color: #351e0c;
  font-size: 1.4em;
  margin: 2em;
`;

const StyledHeader = styled.h1`
  font-family: "Bungee";
  color: #ffbd0c;
  font-size: 2.5em;
  line-height: 1.4em;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
`;
