const faqList = [
  {
    question: "What is TacoCat?",
    answer:
      "TacoCat is a brand expanding into many streams such as lifestyle, gaming, crypto and more! The TacoCat Token (TCT) is a large pillar of the entire ecosystem! The token is woven into all other aspects of the company, and tied together to ensure the mission of TacoCat is completed, which is: bridging the gap between the crypto sphere and mainstream audiences.",
  },
  {
    question: "What are the tokenomics?",
    answer:
      "TCT is a BEP-20 token built on the Binance Smart Chain, with a 9% transaction fee, that we like to call our taconomics. We have 4% being distributed to holders, 3% to liquidity and another 2% sent to our marketing wallet. We hold ownership over the contract, meaning we can alter these numbers as we see fit, with permission of the community!",
  },
  {
    question: "Why is your contract not renounced?",
    answer:
      "We decided to not renounce ownership in order to be able to make necessary tweaks along the way and stay relevant to the ever changing crypto, and especially, BSC space. Our team is fully doxxed, and we hope this provides enough trust to the community, showing that we are utterly and fully honest and transparent, and not here for any shady business!",
  },
  {
    question: "Are you on any exchanges? How can I buy?",
    answer:
      "We are currently listed on five exchanges, that being Hotbit, LBank, Coinsbit, LaToken and BitMart. You can also purchase TCT on PancakeSwap! We have tutorials and help available for anyone who might run into issues along the way!",
  },
  {
    question: "How are you any different to all the other cryptos out there?",
    answer:
      "It’s true that our image portrays a very fun and innocent depiction: a cat inside a taco. However, make no mistake, our goals and plans are very serious, and expand into many use cases and practical utilities. Our whitepaper available on our website goes into great detail as to why we separate ourselves from the vast majority of cryptocurrencies available on the market. We are also the first to attempt building a lifestyle brand backed by a cryptocurrency!",
  },
  {
    question: "How can I tell you are legitimate?",
    answer:
      "As previously stated, our team is publicly doxxed and known, and if you have any questions or hesitation, we invite you to do your own research before investing, as well as joining any of our social media channels to ask any questions you may have. We are particularly active on our Telegram, and are always available to offer help. Many of our team members also regularly post video updates, showing their identities because we are all proud to be part of the TacoCat family!",
  },
  {
    question: "Why should I invest?",
    answer:
      "This is a question which each investor must answer on their own, but the enticing thing about TacoCat is that it is so much more than just a crypto. We are building an entire ecosystem and empire, in which TCT is simply the fuel that powers our utilities and use cases. We intend to spread into every possible area of pop culture, doing so strategically and with purpose, and to become the first crypto-backed lifestyle brand. After all, so many things from all sorts of genres has made it to the mainstream, hence our vision to catapult crypto there as well, and help drive global adoption!",
  },
];

export default faqList;
