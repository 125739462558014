import React from "react";
import { TacoCard } from "../components/sections/tacocard/tacocard";
import { Taconomics } from "../components/sections/taconomics/Taconomics";
import { Hero } from "../components/sections/hero/hero";
import { Connect } from "../components/sections/connect/connect";
import { Footer } from "../components/sections/footer/footer";
import { RoadmapTeaser } from "../components/sections/roadmap-teaser/roadmap-teaser";
import { Partners } from "../components/sections/partners/partners";

function Home() {
  return (
    <>
      <Hero />
      <Partners />
      <TacoCard />
      <Taconomics />
      <RoadmapTeaser />
      <Connect />
      <Footer />
    </>
  );
}

export default Home;
