import styled from "styled-components";

export const Section = styled.section`
  height: 100%;
  background-color: #ffd860;
  font-family: "ForgiaW01Outside";
  padding: 4% 0;

  @media (max-width: 1000px) {
    padding: 8% 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Title = styled.h1`
  display: block;
  color: #ffffff;
  font-size: 2.5em;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  margin: 0 auto;

  @media (max-width: 650px) {
    font-size: 2em;
  }
`;

export const SocialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2%;
  justify-content: space-between;
  width: 600px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding-top: 3%;
  }

  @media (max-width: 800px) {
    width: 500px;
    padding-top: 4%;
  }

  @media (max-width: 650px) {
    width: 200px;
    padding-top: 4%;
  }
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  background-color: #ffbd06;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 1px solid #dc7f06;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 650px) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const TwitterLink = styled.a``;

export const FacebookLink = styled.a``;

export const InstagramLink = styled.a``;

export const RedditLink = styled.a``;

export const TelegramLink = styled.a``;

export const TwitchLink = styled.a``;

export const TikTokLink = styled.a``;

export const MediumLink = styled.a``;

export const YouTubeLink = styled.a``;
