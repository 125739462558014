import React from "react";
import FrequentlyAskedQuestions from "../components/frequently-asked-questions/FrequentlyAskedQuestions";
import { Footer } from "../components/sections/footer/footer";

const FAQs = () => {
  return (
    <>
      <FrequentlyAskedQuestions />
      <Footer />
    </>
  );
};

export default FAQs;
