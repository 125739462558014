import React from "react";
import NewsComponent from "../components/news/News";
import { Footer } from "../components/sections/footer/footer";
import {useNewsFeed} from "../context/NewsFeedContext";

const News = () => {
  const {feed} = useNewsFeed();

  return (
    <>
      <NewsComponent newsFeed={feed}/>
      <Footer />
    </>
  );
};

export default News;
