import React from 'react';
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
const options = {
  transform,
};
function transform(node) {
  console.log('who am i', node);
  if (node.name === 'img' || node.name === 'figure') {
    return null;
  }
  return <StyledHeader>{node.data}</StyledHeader>
}
export default function RichContentRender({data}) {
  return(
    <>
      {ReactHtmlParser(data, options)}
    </>
  );
}
const StyledHeader = styled.h1`
  font-family: "ForgiaW01Outside";
  margin: .4em;
  color: #ffffff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  font-size: 1.4em;
  line-height: 1.4em;
  word-break: break-word;
`;
