import React from "react";
import tacocatFamily from "./images/tacocat-family.png";
import { Link } from "react-router-dom";

import * as S from "./roadmap-teaser.styles";

export const RoadmapTeaser = () => {
  return (
    <S.Section>
      <S.Container>
        <S.CouchGraphicContainer>
          <S.CouchGraphic
            src={tacocatFamily}
            alt="tacocatFamily"
          ></S.CouchGraphic>
        </S.CouchGraphicContainer>
        <S.InfoContainer>
          <S.Title>MORE THAN JUST A TOKEN.</S.Title>
          <S.Description>
            The TacoCat brand is established not just as a token, but a brand at
            the forefront of cryptocurrency innovation. Gaming, music, art,
            literature, food, sports, education and so much more are possible
            with the power of a great brand. The potential is limitless.
          </S.Description>
          <S.ButtonContainer>
            <Link to="/roadmap" style={{ textDecoration: "none" }}>
              <S.Button>
                <S.ButtonCTA>VIEW ROADMAP</S.ButtonCTA>
              </S.Button>
            </Link>
          </S.ButtonContainer>
        </S.InfoContainer>
      </S.Container>
    </S.Section>
  );
};
