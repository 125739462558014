import styled from "styled-components";

export const Hero = styled.section`
  font-family: "BrandonGrotesqueBlackLight";
  padding-top: 1em;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  background-color: #fbebb9;

  @media screen and (max-width: 700px) {
    padding-top: 1em;
  }

  @media screen and (max-width: 500px) {
    padding-bottom: 0;
    /* padding-top: 5em; */
  }
`;

export const Explainer = styled.div`
  text-align: center;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-size: 1.5em;
  line-height: 1.4em;
  padding: 5% 0;

  @media screen and (max-width: 1578px) {
    padding-bottom: 0;
    padding-top: 3%;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-top: 0;
  }

  /* @media screen and (max-width: 500px) {
    margin-top: 2em;
  } */
`;

export const Meet = styled.h1`
  font-size: 1.5em;
  line-height: 1.4em;
  letter-spacing: 2px;
  text-align: left;
  font-family: "ForgiaW01Outside";
  color: #ffbd0c;
  /* padding-bottom: 3%; */
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;

  @media screen and (max-width: 1578px) {
    text-align: center;
    margin-bottom: 3%;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10%;
  }
`;

export const ExplainerContainer = styled.div`
  display: grid;
  gap: 5%;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 600px));
  /* grid-template-rows: 1fr 1fr; */
  height: 100%;

  margin-top: 7%;
  margin: 0 auto;

  @media screen and (max-width: 1578px) {
    gap: 0;
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 0 auto;
    margin-top: 0;
  }

  @media screen and (max-width: 500px) {
    margin-top: 1em;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: center; */
  text-align: left;
  padding: 0 3%;
  font-size: 1.1em;
  /* width: 30%; */

  @media screen and (max-width: 1578px) {
    grid-column: span 2;
    text-align: center;
    margin-bottom: 5%;
  }

  p {
    color: #351e0c;
    font-family: "BrandonGrotesqueBlackLight";
    font-weight: bolder;
    font-size: 0.75em;
    margin-right: auto;
    padding-top: 7%;

    @media screen and (max-width: 1578px) {
      text-align: left;
      padding-top: 0;
    }

    @media screen and (max-width: 650px) {
      width: 90%;
      margin: 0 auto;
      padding-top: 7%;
    }

    @media screen and (max-width: 500px) {
      text-align: center;
      margin-bottom: 10%;
    }
  }
`;

export const Video = styled.div`
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1578px) {
    grid-column: span 2;
    text-align: center;
    /* margin-bottom: 2%; */
  }

  @media screen and (max-width: 1000px) {
    height: 450px;
    margin-top: 2%;
    /* margin-bottom: 1%; */
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 1%;
  }

  @media screen and (max-width: 500px) {
    /* margin: 0 auto; */
    height: 250px;
    margin-bottom: 0;
    margin-top: 5%;
  }
`;

export const TrustWalletSection = styled.section`
  background-color: #ffbd0c;
`;

export const SendBNBSection = styled.section`
  background-color: #713535;
`;

export const PancakeSwapSection = styled.section`
  background-color: #fbebb9;
`;

export const ReadyToBuySection = styled.section`
  background-color: #ffdc5e;
  padding: 3%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  align-items: center;
  width: 90%;
  margin: 0 auto;
  // TODO: Potentially weird value here
  margin-left: 7%;

  @media screen and (max-width: 1400px) {
    width: 100%;
    margin-left: 3%;
  }

  @media screen and (max-width: 900px) {
    margin-left: 0;
  }

  /* TODO: Add a flex wrap */
  /* flex-wrap: wrap; */
`;

export const StepContainer = styled.div`
  display: flex;
  height: 100%;
  /* flex-grow: 0.5; */
  /* margin-left: 4%; */
  width: 60%;
  /* justify-content: space-evenly; */

  @media screen and (max-width: 1230px) {
    width: 75%;
    margin-top: 5%;
  }

  @media screen and (max-width: 900px) {
    width: 90%;
    margin-top: 10%;
  }

  @media screen and (max-width: 500px) {
    margin-top: 15%;
    flex-direction: column;
    margin-left: 0;
  }
`;

export const StepDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.5em;
  margin-left: 21%;

  @media screen and (max-width: 700px) {
    margin: 0 auto;
    width: 90%;
  }

  p {
    color: #351e0c;
    font-family: "BrandonGrotesqueBlackLight";
    font-weight: bolder;
    font-size: 0.85em;
    line-height: 1.5em;

    @media screen and (max-width: 500px) {
      text-align: center;
    }
  }
`;

export const Number = styled.div`
  font-size: 20em;
  display: flex;
  width: 250px;
  /* width: 400px; */
  line-height: 73%;
  justify-content: center;
  align-items: center;
  font-family: "ForgiaW01Outside";
  color: #c5e7fa;
  text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;

  @media screen and (max-width: 1000px) {
    font-size: 17em;
  }

  @media screen and (max-width: 500px) {
    margin: 0 auto;
    margin-bottom: 10%;
  }
`;

export const Title = styled.div``;

export const Copy = styled.div``;

export const Image = styled.div`
  @media (max-width: 1230px) {
    padding-bottom: 5%;
  }
`;

export const QuestionMark = styled.div`
  position: absolute;
  font-size: 10em;
  font-family: "ForgiaW01Outside";
  top: 9%;
  z-index: 2;
  left: 71%;
  transform: translateX(-50%);
  color: #c5e7fa;
  text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;

  /* @media screen and (max-width: 1000px) {
    left: 47%;
    font-size: 17em;
  } */

  /* @media screen and (max-width: 650px) {
    left: 42%;
  } */
`;

export const ReadyToBuyContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    padding-top: 10%;
    padding-bottom: 10%;
  }
`;

export const PlatformContainer = styled.div`
  display: grid;
  gap: 1%;
  width: 70%;
  margin: 3% auto;
  margin-bottom: 1%;
  grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));

  @media screen and (max-width: 650px) {
    width: 90%;
  }

  h3 {
    color: black;
    margin: 10% auto;
    font-family: "ForgiaW01Outside";
    font-size: 1em;
    letter-spacing: 1px;
  }
`;

export const Platform = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Letter = styled.span`
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
`;

export const Button = styled.div`
  display: flex;
  background-color: #fbebb9;
  height: 45px;
  width: 180px;
  border-radius: 30px;
  border: 1px solid #dc7f06;
  text-decoration: none;
  /* margin-bottom: 5%; */
  margin-top: 10%;
  /* margin-right: auto; */
`;

export const ButtonCTA = styled.div`
  font-family: "Bungee";
  color: #ffffff;
  font-size: 14px;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  margin: auto;
`;

export const ButtonWrapper = styled.a`
  text-decoration: none;
  margin-right: auto;
  margin-bottom: 1%;

  @media screen and (max-width: 500px) {
    margin: 2% auto;
  }
`;

export const Break = styled.div`
  @media (max-width: 1230px) {
    flex-basis: 100%;
    height: 0px;
    padding-bottom: 3%;
    /* order: 2; */
    width: 0px;
    /* overflow: hidden; */
    display: inline-block;
  }
`;

// export const StepBreak = styled.div`
//   @media (max-width: 500px) {
//     flex-basis: 100%;
//     height: 0px;
//     padding-bottom: 3%;
//     /* order: 2; */
//     width: 0px;
//     overflow: hidden;
//     display: inline-block;
//   }
// `;
