import React from 'react'
import * as S from "./partners.styles";
import DiamondHandLogo from "./images/dHands1.png";
import MiamiSwimWeekLogo from "./images/miami-swim-week.png";
import BikiniBeachLogo from "./images/bikini-beach-black.png";
import BlockchanieumLogo from "./images/blockchanieum.png";
import OceanPokerLogo from "./images/ocean-poker.png";

export const Partners = () => {
    return (
        <S.Section>
            <S.Container>
                <S.Title>Our Partners</S.Title>
                <S.PartnersContainer>
                    <S.DiamondHands>
                        <img src={DiamondHandLogo} style={{ margin: '0 auto', height: '80%', display: 'block' }} />
                        <p style={{ textAlign: 'center', marginTop: '5px', height: '20%', display: 'block' }}>DiamondHands App</p>
                    </S.DiamondHands>
                    <S.OceanPoker>
                        <img src={OceanPokerLogo} style={{ margin: 'auto auto', height: '100%', display: 'block' }} />
                    </S.OceanPoker>
                    <S.Blockchanieum>
                        <img src={BlockchanieumLogo} style={{ margin: '0 auto', height: '80%', display: 'block' }} />
                        <p style={{ textAlign: 'center', marginTop: '5px', height: '20%', display: 'block' }}>Blockchaineum</p>
                    </S.Blockchanieum>
                    <S.BikiniBeach>
                        <img src={BikiniBeachLogo} style={{ margin: '0 auto', height: '100%', display: 'block' }} />
                    </S.BikiniBeach>
                    <S.MiamiSwimWeek>
                        <img src={MiamiSwimWeekLogo} style={{ margin: '0 auto', height: '100%', display: 'block' }} /> 
                    </S.MiamiSwimWeek>
                </S.PartnersContainer>
            </S.Container>
        </S.Section>
    );
};
