import styled from "styled-components";

export const Section = styled.section`
  height: 100%;
  background-color: #ffd860;
  font-family: "ForgiaW01Outside";
  padding: 4% 0;

  @media (max-width: 1000px) {
    padding: 8% 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Title = styled.h1`
  display: block;
  color: #ffff;
  font-size: 2.5em;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  margin: 0 auto;

  @media (max-width: 650px) {
    font-size: 2em;
  }
`;

export const PartnersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2%;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 70%;
    padding-top: 3%;
  }

  @media (max-width: 1200px) {
    width: 80%;
    padding-top: 4%;
  }

  @media (max-width: 1040px) {
    display: block;
  }
`;

export const DiamondHands = styled.div`
  height: 120px;

  @media (max-width: 1040px) {
    height: 100px;
  }
`;
export const MiamiSwimWeek = styled.div`
  height: 120px;

  @media (max-width: 1040px) {
    height: 100px;
    margin-top: 20px;
  }
`;
export const BikiniBeach = styled.div`
  height: 120px;

  @media (max-width: 1040px) {
    height: 100px;
    margin-top: 20px;
  }
`;
export const Blockchanieum = styled.div`
  height: 120px;

  @media (max-width: 1040px) {
    height: 100px;
    margin-top: 20px;
  }
`;
export const OceanPoker = styled.div`
  height: 120px;

  @media (max-width: 1040px) {
    height: 100px;
    margin-top: 20px;
  }
`;