import React from "react";
import { Footer } from "../components/sections/footer/footer";

const Minigame = () => {
  return (
    <>
      <iframe
        style={{
          border: 0,
          minHeight: "100vh",
          left: 0,
          top: 0,
          width: "100%",
        }}
        title="minigame"
        src="./game.html"
      />
      <Footer />
    </>
  );
};

export default Minigame;
