import React from "react";
import HowToBuyComponent from "../components/how-to-buy/HowToBuy";
import { Footer } from "../components/sections/footer/footer";

const HowToBuy = () => {
  return (
    <>
      <HowToBuyComponent />
      <Footer />
    </>
  );
};

export default HowToBuy;
