import styled from "styled-components";

export const Section = styled.section`
  height: 100%;
  background-color: #713535;
  font-family: "ForgiaW01Outside";
  padding: 1.8% 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 65%;
  padding: 20px 0;

  @media (max-width: 1200px) {
    width: 75%;
  }

  @media (max-width: 1000px) {
    width: 85%;
  }

  @media (max-width: 800px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 80%;
    justify-content: space-between;
    padding: 8% 0;
  }
`;

export const TacoCatLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    order: 1;
    margin: 0 auto;
  }
`;

export const TacoCatLogo = styled.img`
  width: 95px;
`;

export const Disclaimer = styled.div`
  width: 45%;

  @media (max-width: 650px) {
    width: 50%;
    justify-content: space-between;
    order: 3;
    width: 100%;
    padding-bottom: 8%;
  }
`;

export const DisclaimerTitle = styled.h3`
  color: #ffbd0c;
  font-family: "ForgiaW01Outside";

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const DisclaimerBody = styled.p`
  font-family: "BrandonGrotesqueBlackLight";
  font-size: 0.9em;
  color: #ffffff;
  line-height: 1.4em;
  margin-top: 1%;
`;

export const WhereToBuy = styled.ul`
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    text-align: left;
    order: 4;
  }
`;

export const WhereToBuyTitle = styled.h3`
  color: #ffbd0c;
  font-family: "ForgiaW01Outside";
  margin-bottom: 3%;
`;

export const WhereToBuyLink = styled.a`
  font-family: "BrandonGrotesqueBlackLight";
  font-size: 0.9em;
  color: #ffffff;
  line-height: 1.4em;
  margin-top: 1%;
`;

export const SiteLinks = styled.ul`
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    text-align: left;
    order: 5;
  }
`;

export const SiteLinksTitle = styled.h3`
  color: #ffbd0c;
  font-family: "ForgiaW01Outside";
  margin-bottom: 3%;
`;

export const SiteLink = styled.a`
  font-family: "BrandonGrotesqueBlackLight";
  font-size: 0.9em;
  color: #ffffff;
  line-height: 1.4em;
  margin-top: 1%;
`;

export const Break = styled.div`
  @media (max-width: 650px) {
    flex-basis: 100%;
    height: 0;
    padding-bottom: 10%;
    order: 2;
  }
`;
