import React from 'react';
import TeamComponent from '../components/team/Team';
import { Footer } from  '../components/sections/footer/footer';

const Team = () => {
  return (
    <>
      <TeamComponent />
      <Footer />
    </>
  );
}

export default Team;