import React from "react";
import {Card} from "react-card-component";
import "./News.css";
import styled from "styled-components";
import RichContentRender from "../rich-content-render";

const getFormattedDate = (date) => {
  if(date){
    return new Intl.DateTimeFormat('en-US', {dateStyle: "medium"}).format(new Date(date.replace(/-/g, "/")));
  }
  return null;
}
const News = ({newsFeed}) => {
  return (
    <StyledSection>
      <GutterContainer>
        <HeaderContainer>
          <StyledHeader>Tacocentral</StyledHeader>
          <StyledHeader>News direct to you</StyledHeader>
        </HeaderContainer>
        <ContentContainer>
          {newsFeed?.items.map(item => (
            <Card hoverType="zoom" bordered={false} style={{background: '#fbebb9', border: 0,}}>
              <ImageContainer>
                <img
                  src={item.thumbnail}
                  height="100%"
                  width="100%"
                />
              </ImageContainer>
              <StyledParagraph>{getFormattedDate(item?.pubDate)}</StyledParagraph>
             <RichContentRender data={item?.title} />
              <div style={{margin: '.6em',}}>
                <a className="buyTacoCat" href={item?.link} target="_blank" rel="noopener noreferrer">Read More</a>
              </div>
            </Card>
          ))}
        </ContentContainer>
      </GutterContainer>
    </StyledSection>
  );
};
const StyledSection = styled.section`
  background-color: #fbebb9;
  min-height: 100vh;
  padding-top: 3em;
  padding-bottom: 3em;
`;

const HeaderContainer = styled.div`
  margin-top: 1.4em;
  margin-bottom: 2.4em;
  margin-left: 1.4em;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-gap: 4em;
  grid-template-columns: repeat(auto-fill, minmax(8em, 56em));
  grid-template-rows: minmax(8em, 32em);
  justify-content: center;
  align-items: center;
  justify-items: center;
`;

const StyledParagraph = styled.p`
  margin: .6em;
  font-size: 1.2em;
  font-family: "BrandonGrotesqueBlackLight";
  color: #1eaa40;
`;

const GutterContainer = styled.div`
  margin: 2em 4em 2em 4em;
  @media screen and (max-width: 500px) {
    width: 90%;
    margin: 0 auto;
  }
`;
const ImageContainer = styled.div`
  height: 50%;
  @media screen and (max-width: 500px) {
    height: 40%;
  }
`;

const StyledHeader = styled.h1`
  font-family: "Bungee";
  margin: .4em;
  color: #ffbd0c;
  font-size: 1.5em;
  line-height: 1.4em;
  word-break: break-word;
`;
export default News;
