import React from "react";
import TermsOfServiceComponent from "../components/terms-of-service/TermsOfService";
import { Footer } from "../components/sections/footer/footer";

const TermsOfService = () => {
  return (
    <>
      <TermsOfServiceComponent />
      <Footer />
    </>
  );
};

export default TermsOfService;
