import styled from "styled-components";

export const Section = styled.section`
  height: 100%;
  background-color: #fbebb9;
  font-family: "ForgiaW01Outside";
  padding-top: 5%;
  padding-bottom: 5%;

  @media (max-width: 650px) {
    padding-bottom: 8%;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 72%;
  margin: 0 auto;

  @media (max-width: 960px) {
    width: 80%;
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column-reverse;
    position: unset;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const CoinsCointainer = styled.div`
  position: absolute;
  right: 3%;
  left: 44%;
  top: -35%;
  z-index: 4;

  @media (max-width: 3440px) {
    top: -18%;
  }

  @media (max-width: 2550px) {
    top: -25%;
  }

  @media (max-width: 2000px) {
    top: -14%;
  }

  @media (max-width: 1550px) {
    top: -10%;
  }

  @media (max-width: 1300px) {
    top: -7%;
  }

  @media (max-width: 1250px) {
    top: -2%;
  }

  @media (max-width: 1120px) {
    top: 3%;
    right: 5%;
    left: 38%;
  }

  @media (max-width: 1000px) {
    top: 8%;
    right: 2%;
    left: 39%;
  }

  @media (max-width: 960px) {
    top: 10%;
    right: 6%;
    left: 42%;
  }

  @media (max-width: 900px) {
    top: 12%;
  }

  @media (max-width: 800px) {
    top: 14%;
    right: 5%;
  }

  @media (max-width: 650px) {
    display: flex;
    position: unset;
    width: 80%;
    height: 100%;
    padding-bottom: 3%;
  }
`;

export const CoinsImage = styled.img`
  max-width: 1100px;
  width: 100%;

  @media (max-width: 2550px) {
    width: 100%;
  }

  @media (max-width: 1950px) {
    width: 112%;
  }

  @media (max-width: 1850px) {
    width: 120%;
  }

  @media (max-width: 1720px) {
    width: 125%;
  }

  @media (max-width: 1650px) {
    width: 130%;
  }

  @media (max-width: 650px) {
    display: flex;
    width: 128%;
    height: 100%;
    padding-bottom: 3%;
  }
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  display: block;
  font-family: "ForgiaW01Outside";
  font-size: 5.5em;
  letter-spacing: 2px;
  color: #ffbd0c;
  text-shadow: -3px 0 #dc7f06, -3px 0 #dc7f06, -3px 0 #dc7f06, -3px 0 #dc7f06;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (max-width: 2550px) {
    font-size: 3em;
  }

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const Description = styled.p`
  font-family: "BrandonGrotesqueBlack";
  font-size: 1.9em;
  line-height: 1.5em;
  color: #350c0c;
  text-shadow: 2px 2px 14px rgba(150, 150, 150, 0.5);
  width: 40%;
  padding-bottom: 3%;
  padding-top: 1.5%;

  @media (max-width: 2550px) {
    font-size: 1.2em;
  }

  @media (max-width: 1000px) {
    padding-bottom: 3%;
  }

  @media (max-width: 650px) {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 1000px;
  padding-bottom: 1%;
  padding-top: 2%;
  z-index: 2;

  @media (max-width: 1250px) {
    width: 52%;
  }

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
    padding-top: 0;
  }

  @media (max-width: 650px) {
    text-align: center;
    width: 90%;
    margin: 1% auto;
    margin-bottom: 13%;
    padding: 2% 0;
  }
`;

export const Holders = styled.div`
  @media (max-width: 1120px) {
    padding-bottom: 4%;
    text-align: left;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
  }
`;

export const HoldersStat = styled.div`
  font-family: "Baloo 2";
  font-size: 1.8em;
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  text-align: center;
  padding-bottom: 10px;
`;

export const HoldersTitle = styled.div`
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  color: #fbebb9;
  text-shadow: -1.3px 0 #dc7f06, 0 1.3px #dc7f06, 1.3px 0 #dc7f06,
    0 -1.3px #dc7f06;
  letter-spacing: 3px;
  text-align: center;

  @media (max-width: 1120px) {
    text-align: left;
  }

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const MarketCap = styled.div`
  padding-right: 7%;
  width: 170px;

  @media (max-width: 1120px) {
    padding-bottom: 4%;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
    padding-right: 0;
  }
`;

export const MarketCapStat = styled.div`
  font-family: "Baloo 2";
  font-size: 1.9em;
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  text-align: left;
  padding-bottom: 10px;

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const MarketCapTitle = styled.div`
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  color: #fbebb9;
  text-shadow: -1.3px 0 #dc7f06, 0 1.3px #dc7f06, 1.3px 0 #dc7f06,
    0 -1.3px #dc7f06;
  letter-spacing: 3px;
  text-align: left;

  @media (max-width: 1120px) {
    text-align: left;
  }

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const Liquidity = styled.div`
  @media (max-width: 1120px) {
    padding-bottom: 4%;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
    padding-bottom: 2%;
  }
`;

export const LiquidityStat = styled.div`
  font-family: "Baloo 2";
  font-size: 1.9em;
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  text-align: left;
  padding-bottom: 10px;

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const LiquidityTitle = styled.div`
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  color: #fbebb9;
  text-shadow: -1.3px 0 #dc7f06, 0 1.3px #dc7f06, 1.3px 0 #dc7f06,
    0 -1.3px #dc7f06;
  letter-spacing: 3px;
  margin: 0 auto;
  text-align: left;

  @media (max-width: 1120px) {
    text-align: left;
  }

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 286px;
  margin-top: 1.5%;
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  letter-spacing: 1px;

  @media (max-width: 650px) {
    justify-content: space-around;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 3%;
  }

  @media (max-width: 550px) {
    width: 98%;
  }
`;

export const BSCScan = styled.div`
  @media (max-width: 650px) {
    display: grid;
    gap: 7%;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 0.8em;
    color: #350c0c;
  }
`;

export const CoinGecko = styled.div`
  @media (max-width: 650px) {
    display: grid;
    gap: 7%;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 0.8em;
    color: #350c0c;
  }
`;

export const CoinMarketCap = styled.div`
  @media (max-width: 650px) {
    display: grid;
    gap: 7%;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 0.8em;
    color: #350c0c;
  }
`;

export const MobileTitle = styled.div`
  @media (max-width: 650px) {
    max-width: 45px;
    position: relative;
    color: #350c0c;
  }
`;

export const DexTools = styled.div`
  @media (max-width: 650px) {
    display: grid;
    gap: 7%;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 0.8em;
    color: #350c0c;
  }
`;

export const GraphicContainer = styled.div``;

export const ButtonWrapper = styled.a`
  text-decoration: none;
  margin-right: auto;
  margin-bottom: 1%;

  @media (max-width: 1120px) {
    margin-bottom: 4%;
  }

  @media (max-width: 650px) {
    margin: 3% auto;
    margin-bottom: 5%;
    text-align: center;
  }
`;

export const Button = styled.div`
  display: flex;
  background-color: #ffbd0c;
  height: 45px;
  width: 180px;
  border-radius: 30px;
  border: 1px solid #dc7f06;
  text-decoration: none;
  margin-bottom: 5%;
  margin-top: 0.3%;
  margin-right: auto;

  @media (max-width: 1120px) {
    margin-bottom: 1%;
  }

  @media (max-width: 650px) {
    margin: 3% auto;
    margin-bottom: 5%;
    text-align: center;
  }
`;

export const ButtonCTA = styled.div`
  font-family: "Bungee";
  color: #ffffff;
  font-size: 14px;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  margin: auto;
`;

export const WhereToBuyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3%;
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  width: 347px;
  letter-spacing: 2px;

  @media (max-width: 650px) {
    width: 100%;
    justify-content: space-around;
  }

  @media (max-width: 550px) {
    width: 90%;
    margin: 10% auto;
    margin-bottom: 7%;
  }
`;

export const WhereToBuy = styled.p`
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  color: #fbebb9;
  letter-spacing: 2px;
  text-shadow: -1.3px 0 #351e0c, 0 1.3px #351e0c, 1.3px 0 #351e0c,
    0 -1.3px #351e0c;
  margin-top: 3%;

  @media (max-width: 650px) {
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: -4%;
  }

  @media (max-width: 500px) {
    margin-bottom: -2%;
  }
`;

export const PlatformContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 347px;
  margin-top: 7%;
  flex-wrap: wrap;

  @media (max-width: 650px) {
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 550px) {
    width: 80%;
  }
`;

export const Volume = styled.div`
  @media (max-width: 1120px) {
    padding-bottom: 4%;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
  }
`;

export const VolumeStat = styled.div`
  font-family: "Baloo 2";
  font-size: 1.9em;
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  text-align: center;
  padding-bottom: 10px;
`;

export const VolumeTitle = styled.div`
  font-family: "ForgiaW01Outside";
  font-size: 0.9em;
  color: #fbebb9;
  text-shadow: -1.3px 0 #dc7f06, 0 1.3px #dc7f06, 1.3px 0 #dc7f06,
    0 -1.3px #dc7f06;
  letter-spacing: 3px;
  margin: 0 auto;
  text-align: left;

  @media (max-width: 1120px) {
    text-align: left;
  }

  @media (max-width: 650px) {
    text-align: center;
  }
`;

export const Platform = styled.div`
  @media (max-width: 650px) {
    display: grid;
    gap: 7%;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 0.8em;
    color: #350c0c;
  }
`;

export const Break = styled.div`
  display: none;

  @media (max-width: 550px) {
    flex-basis: 100%;
    height: 0px;
    /* padding-bottom: 3%; */
    /* order: 2; */
    width: 0px;
    /* overflow: hidden; */
    display: inline-block;
  }
`;
