import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WebFont from 'webfontloader';
import './fonts/ForgiaW01Outside/ForgiaW01Outside.ttf';
import './fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlack.otf'
import './fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlackLight.otf'

import './index.css'

WebFont.load({
  custom: {
    families: ['ForgiaW01Outside', 'BrandonGrotesqueBlack', 'BrandonGrotesqueBlackLight'],
    urls: [
      './fonts/ForgiaW01Outside/ForgiaW01Outside.ttf',
      './fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlack.otf',
      './fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlackLight.otf',
    ]
  },
  google: {
    families: ['Baloo 2', 'Bungee']
  },
  fontactive: () => ReactDOM.render(<App />, document.getElementById('root'))
});