import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as S from "./connect.styles";

export const Connect = () => {
  return (
    <S.Section>
      <S.Container>
        <S.Title>CONNECT WITH US</S.Title>
        <S.SocialsContainer>
          <S.TwitterLink>
            <S.Button
              href="https://twitter.com/tacocattoken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "twitter"]}
              />
            </S.Button>
          </S.TwitterLink>
          <S.FacebookLink>
            <S.Button
              href="https://facebook.com/tacocattoken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "facebook"]}
              />
            </S.Button>
          </S.FacebookLink>
          <S.InstagramLink>
            <S.Button
              href="https://instagram.com/tacocattoken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "instagram"]}
              />
            </S.Button>
          </S.InstagramLink>
          <S.RedditLink>
            <S.Button
              href="https://www.reddit.com/r/TacoCatCo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "reddit"]}
              />
            </S.Button>
          </S.RedditLink>
          <S.TelegramLink>
            <S.Button
              href="https://t.me/tacocattoken"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "telegram"]}
              />
            </S.Button>
          </S.TelegramLink>
          <S.TwitchLink>
            <S.Button
              href="https://www.twitch.tv/tacocatcrypto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "twitch"]}
              />
            </S.Button>
          </S.TwitchLink>
          <S.TikTokLink>
            <S.Button
              href="https://www.tiktok.com/@tacocat_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "tiktok"]}
              />
            </S.Button>
          </S.TikTokLink>
          <S.MediumLink>
            <S.Button
              href="https://tacocattoken.medium.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "medium"]}
              />
            </S.Button>
          </S.MediumLink>
          <S.YouTubeLink>
            <S.Button
              href="https://www.youtube.com/channel/UCxE86z2XCUAlWINncEjmG5g"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                style={{ margin: "0 auto" }}
                size={"2x"}
                color="white"
                icon={["fab", "youtube"]}
              />
            </S.Button>
          </S.YouTubeLink>
        </S.SocialsContainer>
      </S.Container>
    </S.Section>
  );
};
