import React from 'react';
import RoadmapComponent from '../components/roadmap/roadmap';
import { Footer } from  '../components/sections/footer/footer';

const Roadmap = () => {
  return (
    <>
      <RoadmapComponent />
      <Footer />
    </>
  );
}

export default Roadmap;