import React from "react";
import "./tacocard.css";
import tacoCardImage from "./images/HandsHoldingCard.png";
import pdf from "../../../TacoCat-whitepaper.pdf";
import styled from "styled-components";

const ButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  background-color: #fbebb9;
  height: 45px;
  width: 220px;
  border-radius: 30px;
  margin-top: 30px;
  border: 1px solid #dc7f06;
  text-decoration: none;
  margin-right: auto;

  @media (max-width: 1046px) {
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    margin-bottom: 2%;
    margin-top: 8%;
  }
`;

const ButtonCTA = styled.div`
  font-family: "Bungee";
  color: #ffffff;
  font-size: 14px;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  margin: auto;
`;

export const TacoCard = () => {
  return (
    <section className="tacoCardSection" id="tacoCard">
      <div className="tacoCardExplainer">
        <div>
          <h2 className="heading">ONE ACCOUNT.</h2>
        </div>
        <div>
          <h2 className="heading">A WHOLE NETWORK.</h2>
        </div>
        <div>
          <p className="description">
            TacoID is your exclusive account connecting you to all things
            TacoCat. Everything is linked to this, including the TacoCat Token
            Network, TacoCat social community and TacoCat Interactive products -
            making TacoCat the easiest cryptocurrency platform to use.
          </p>
        </div>
        <ButtonWrapper
          style={{ textDecoration: "none" }}
          href={pdf}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonCTA>Learn more</ButtonCTA>
        </ButtonWrapper>
      </div>
      <div className="tacoCardImageContainer">
        <img className="tacoCardImage" src={tacoCardImage} alt="tacocard"></img>
      </div>
    </section>
  );
};
