import React, { useState, useEffect } from "react";
import TacoCatCompanyLogo from "./images/TacoCatCompanyLogo.png";
import { Link as RouterLink } from "react-router-dom";
import "./Navbar.css";
import pdf from "../../TacoCat-whitepaper.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const [crossLink, setCrossLink] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleMobile = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
      setCrossLink(false);
    } else {
      setButton(true);
      setCrossLink(true);
    }
  };

  useEffect(() => {
    handleMobile();
  }, []);

  window.addEventListener("resize", handleMobile);

  const changeBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <nav className={navbar ? "navbar active" : "navbar"}>
        <div className="navbar-container">
          <div className="navbarLogo">
            <RouterLink to="/" className="navbar-logo-container">
              <img
                src={TacoCatCompanyLogo}
                className="logo"
                alt="tacocat logo"
              />
            </RouterLink>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {/* TODO: POINT TO /BUY & FIX STYLING */}
            {button || (
              <div className="buyTacoCatHamburger">
                <RouterLink
                  style={{ textDecoration: "none" }}
                  className="buyTacoCatCTAHamburger"
                  to="/how-to-buy"
                  onClick={closeMobileMenu}
                  smooth={true}
                  duration={1000}
                >
                  HOW TO BUY
                </RouterLink>
              </div>
            )}
            {button || (
              <li className="nav-item">
                <RouterLink
                  to="/"
                  className="nav-links"
                  smooth={true}
                  duration={1000}
                  onClick={closeMobileMenu}
                >
                  Home
                </RouterLink>
              </li>
            )}

            <li className="nav-item">
              <a
                href={pdf}
                className="nav-links"
                onClick={closeMobileMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitepaper
              </a>
            </li>

            <li className="nav-item">
              <RouterLink
                to="/team"
                className="nav-links"
                smooth={true}
                duration={1000}
                onClick={closeMobileMenu}
              >
                Team
              </RouterLink>
            </li>

            {/* <li className="nav-item">
              <RouterLink
                to="/news"
                className="nav-links news"
                smooth={true}
                duration={1000}
                onClick={closeMobileMenu}
              >
                News
              </RouterLink>
            </li> */}

            <li className="nav-item">
              <RouterLink
                to="/roadmap"
                className="nav-links"
                smooth={true}
                duration={1000}
                onClick={closeMobileMenu}
              >
                Roadmap
              </RouterLink>
            </li>

            <li className="nav-item">
              <a
                href="https://shop.tacocat.co"
                className="nav-links"
                onClick={closeMobileMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop
              </a>
            </li>

            <li className="nav-item">
              <RouterLink
                to="/minigame"
                className="nav-links"
                smooth={true}
                duration={1000}
                onClick={closeMobileMenu}
              >
                Flappy
              </RouterLink>
            </li>

            {crossLink && (
              <li className="nav-item">
                <RouterLink
                  to="/minigame-frogger"
                  className="nav-links"
                  smooth={true}
                  duration={1000}
                  onClick={closeMobileMenu}
                >
                  Crosser
                </RouterLink>
              </li>
            )}

            <li className="nav-item">
              <RouterLink
                to="/charity"
                className="nav-links"
                smooth={true}
                duration={1000}
                onClick={closeMobileMenu}
              >
                Charity
              </RouterLink>
            </li>
          </ul>
          {button && (
            <RouterLink
              to="/how-to-buy"
              className="buyTacoCat"
              smooth={true}
              duration={1000}
              onClick={closeMobileMenu}
            >
              HOW TO BUY
            </RouterLink>
          )}
        </div>
        {/* HAMBURGER ICON */}
        {click ? (
          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon
              style={{ margin: "0 auto", color: "#350c0c" }}
              size={"1x"}
              color="white"
              icon={["fas", "times"]}
            />
          </div>
        ) : (
          <div className="menu-icon" onClick={handleClick}>
            <FontAwesomeIcon
              style={{ margin: "0 auto", color: "#350c0c" }}
              size={"1x"}
              color="white"
              icon={["fas", "bars"]}
            />
          </div>
        )}
      </nav>
    </>
  );
}

export default Navbar;
