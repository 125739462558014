import React from "react";
import CharityComponent from "../components/charity/Charity";
import { Footer } from "../components/sections/footer/footer";

const Charity = () => {
  return (
    <>
      <CharityComponent />
      <Footer />
    </>
  );
};

export default Charity;
