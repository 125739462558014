import React from "react";
import { Link } from "react-router-dom";
import tacoCatCompanyLogo from "./images/TacoCatCompanyLogo.png";
import pdf from "../../../TacoCat-whitepaper.pdf";

import * as S from "./footer.styles";

export const Footer = () => {
  return (
    <S.Section>
      <S.Container>
        <S.TacoCatLogoContainer>
          <Link to="/">
            <S.TacoCatLogo
              src={tacoCatCompanyLogo}
              alt="tacoCatCompanyLogo"
            ></S.TacoCatLogo>
          </Link>
        </S.TacoCatLogoContainer>
        <S.Disclaimer>
          <S.DisclaimerTitle>DISCLAIMER</S.DisclaimerTitle>
          <S.DisclaimerBody>
            Cryptocurrencies can be highly volatile investments which constitute
            the possibility of great price swings across often short periods of
            time. With that in mind, the Whitepaper does not represent any
            financial advice and should not be treated as such, in any way.
          </S.DisclaimerBody>
        </S.Disclaimer>
        <S.Break></S.Break>
        <S.WhereToBuy>
          <S.WhereToBuyTitle>WHERE TO BUY</S.WhereToBuyTitle>
          <a
            style={{ textDecoration: "none" }}
            href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2095d2346e47Ed497d4F39FcfA59918b4346cd65"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WhereToBuyLink>PancakeSwap</S.WhereToBuyLink>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.bitmart.com/trade/en?layout=basic&symbol=TCT_USDT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WhereToBuyLink>Bitmart</S.WhereToBuyLink>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="https://latoken.com/exchange/TCT_USDT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WhereToBuyLink>LA Token</S.WhereToBuyLink>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.lbank.info/exchange/tct/usdt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WhereToBuyLink>LBank</S.WhereToBuyLink>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.hotbit.io/exchange?symbol=TCT_USDT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WhereToBuyLink>Hotbit</S.WhereToBuyLink>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="https://coinsbit.io/trade_classic/TCT_USDT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.WhereToBuyLink>Coinsbit</S.WhereToBuyLink>
          </a>
        </S.WhereToBuy>

        <S.SiteLinks>
          <S.SiteLinksTitle>LINKS</S.SiteLinksTitle>
          <Link style={{ textDecoration: "none" }} to="/terms-of-service">
            <S.SiteLink>Terms of Service</S.SiteLink>
          </Link>
          <a
            style={{ textDecoration: "none" }}
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.SiteLink>Whitepaper</S.SiteLink>
          </a>
          <Link style={{ textDecoration: "none" }} to="/team">
            <S.SiteLink>Team</S.SiteLink>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/roadmap">
            <S.SiteLink>Roadmap</S.SiteLink>
          </Link>
          <a
            style={{ textDecoration: "none" }}
            href="https://shop.tacocat.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.SiteLink>Shop</S.SiteLink>
          </a>
          <Link style={{ textDecoration: "none" }} to="/charity">
            <S.SiteLink>Charity</S.SiteLink>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/faqs">
            <S.SiteLink>FAQs</S.SiteLink>
          </Link>
        </S.SiteLinks>
      </S.Container>
    </S.Section>
  );
};
