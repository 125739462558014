import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Widget } from "@typeform/embed-react";
import desertBackground from "./images/desert-bg.png";
import Skeleton from "react-loading-skeleton";
import AnimatedNumber from "animated-number-react";

const Section = styled.section`
  font-family: "BrandonGrotesqueBlackLight";
  padding-top: 1em;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  background-color: #fbebb9;

  @media screen and (max-width: 700px) {
    padding-top: 1em;
  }
`;

const Explainer = styled.div`
  text-align: center;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  font-size: 1.5em;
  line-height: 1.5em;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-top: 0;
  }
`;

const Meet = styled.h1`
  font-size: 1.5em;
  line-height: 1.4em;
  font-family: "ForgiaW01Outside";
  color: #ffbd0c;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
`;

const CharityWalletBalance = styled.div`
  display: flex;
  flex-direction: column;
  height: 650px;
  background-image: url(${desertBackground});
  background-size: cover;
  min-height: 30vh;
  border-radius: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 700px) {
    margin-bottom: 14%;
  }
`;

const CharityBalanceTitle = styled.h1`
  line-height: 1.4em;
  font-family: "ForgiaW01Outside";
  color: #ffbd0c;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  padding-bottom: 3%;
  margin-top: -10%;
  font-size: 2em;

  @media screen and (max-width: 700px) {
    margin-top: -35%;
  }
`;

const ExplainerContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  height: 100%;

  border-radius: 20px;
  margin-top: 7%;

  @media screen and (max-width: 700px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 0;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3%;

  p {
    color: #351e0c;
    font-family: "BrandonGrotesqueBlackLight";
    font-weight: bolder;
    font-size: 0.85em;
  }
`;

const Thought = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3%;
  font-style: italic;

  @media screen and (max-width: 1000px) {
    height: 450px;
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 12%;
  }
`;

const QuestionMark = styled.div`
  position: absolute;
  font-size: 20em;
  font-family: "ForgiaW01Outside";
  left: 42%;
  transform: translateX(-50%);
  color: #c5e7fa;
  text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;

  @media screen and (max-width: 1000px) {
    left: 47%;
    font-size: 17em;
  }

  @media screen and (max-width: 650px) {
    left: 42%;
  }
`;

const Balance = styled.div`
  font-family: "Baloo 2";
  font-size: 1.9em;
  color: #ffbd0c;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  text-align: left;
  padding-bottom: 10px;

  @media screen and (max-width: 650px) {
    padding-top: 2%;
  }
`;

const Charity = () => {
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [isMobile, setIsMobile] = useState();

  const formatValue = (value, decimal, prefix = true, tct) =>
    `${prefix ? "$" : ""}${Number(value).toFixed(decimal)}`;

  const setViewportWidth = () => {
    if (window.innerWidth <= 650) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    setViewportWidth();

    // TODO: UNCOMMENT this when we wanna start properly tracking the wallet.
    // async function fetchData() {
    //   const BSCResponse = await fetch(
    //     `https://cors.bridged.cc/https://api.bscscan.com/api?module=account&action=addresstokenbalance&address=0x7E496322Ea22AaAE76912b73DDBC1dB480CE35BF&page=1&offset=100&apikey=${process.env.REACT_APP_BSC_SCAN_KEY}`,
    //     {
    //       method: "GET",
    //       json: true,
    //       gzip: true,
    //       credentials: "same-origin",
    //     }
    //   );

    //   const BSCData = await BSCResponse.json();

    //   const TCTBalance = BSCData.result.filter(
    //     (item) => item.TokenName === "TACOCAT Token"
    //   )[0].TokenQuantity;

    //   // TODO: Log a request with BSC Scan. Their token holding results are fucked.
    //   const formattedBalance = Number(TCTBalance.substring(0, 8));

    //   setWalletBalance(formattedBalance);

    //   setLoading(false);
    // }
    // fetchData();
  }, []);

  return (
    <Section>
      <Explainer>
        <CharityWalletBalance>
          <CharityBalanceTitle>LIVE Charity Wallet Balance</CharityBalanceTitle>
          <Balance>
            {loading ? (
              <Skeleton style={{ borderRadius: "20px" }} />
            ) : (
              <>
                <AnimatedNumber
                  value={5000}
                  duration={2000}
                  formatValue={(value) =>
                    String(formatValue(value, 0)).replace(
                      /(.)(?=(\d{3})+$)/g,
                      "$1,"
                    )
                  }
                  delay={0}
                />
                <span>&nbsp;USD</span>
              </>
            )}
          </Balance>
        </CharityWalletBalance>

        <ExplainerContainer>
          <Details>
            <Meet
              style={{
                textShadow:
                  "-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white",
              }}
            >
              Are you a charity?
            </Meet>
            <p>
              <br></br>
              We'd love to hear from you!
              <br></br>
              <br></br>
              TacoCat is more than just a cute face. Every week, he gives $5000+
              to a selected charity and then kicks back, cooks his favourite
              taco and pats himself on the belly.
              <br></br>
              <br></br>
              Let us know if TacoCat can help out. Simply fill out the quick
              form below and we'll get back to you if you're selected.
              <br></br>
              <br></br>
              We give donations in a popular cryptocurrency which is pegged to
              the value of the US Dollar - Binance USD. BUSD can be converted
              into any store of value you'd like, including normal fiat
              currency. To receive a donation in BUSD, you would just need to
              set up a wallet.
              <br></br>
              <br></br>
              Don't worry - it's super easy. Just follow the TrustWallet steps
              in this short TacoCat tutorial video{" "}
              <a
                href="https://youtu.be/fRQsd20d46Q"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#DC7F06" }}
              >
                tutorial
              </a>
              .
            </p>
          </Details>
          <Thought>
            <Meet
              style={{
                zIndex: 2,
                letterSpacing: "3px",
                textShadow:
                  "-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white",
              }}
            >
              What's the point of success if it can't be used to make the world
              just a little bit better?
            </Meet>
            <QuestionMark>?</QuestionMark>
          </Thought>
        </ExplainerContainer>

        <Widget
          id="OuV6Efzp"
          style={{
            width: "100%",
            margin: "7% auto",
            height: isMobile ? "600px" : "800px",
            borderRadius: "20px",
          }}
          className="myForm"
        />
      </Explainer>
    </Section>
  );
};

export default Charity;
