import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  height: 100%;
  background-color: #ffbd0c;
  font-family: "ForgiaW01Outside";
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  width: 80%;
  padding: 50px 0;

  @media (max-width: 1000px) {
    width: 90%;
    padding: 5% 0;
  }

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    padding-top: 10%;
    padding-bottom: 12%;
    width: 90%;
  }
`;

export const CouchGraphicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7%;
  padding-right: 10%;

  @media (max-width: 650px) {
    padding-top: 5%;
    padding-bottom: 10%;
  }
`;

export const CouchGraphic = styled.img`
  width: 300px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-family: "ForgiaW01Outside";
  font-size: 2.5em;
  color: #351e0c;
  margin-top: 18px;
  margin-bottom: 13px;
  width: 350px;
  line-height: 1em;

  @media (max-width: 650px) {
    margin: 0 auto;
    padding-bottom: 6%;
    text-align: center;
  }
`;

export const Description = styled.p`
  color: #351e0c;
  font-family: "BrandonGrotesqueBlackLight";
  font-weight: bolder;
  font-size: 1.2em;
  line-height: 1.5em;
  padding-bottom: 25px;

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
    font-size: 1.2em;
    padding-bottom: 8%;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  margin-right: auto;

  @media (max-width: 650px) {
    margin: 0 auto;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #fbebb9;
  height: 45px;
  width: 220px;
  border-radius: 30px;
  border: 1px solid #dc7f06;
  text-decoration: none;

  @media (max-width: 650px) {
    margin: 0 auto;
    text-align: center;
  }
`;

export const ButtonCTA = styled.div`
  font-family: "Bungee";
  color: #ffffff;
  font-size: 14px;
  text-shadow: -1px 0 #dc7f06, 0 1px #dc7f06, 1px 0 #dc7f06, 0 -1px #dc7f06;
  margin: auto;
`;
