import React, {createContext, useContext} from "react";
import useSWR from "swr";
import {fetcher} from "../fetcher";
let feedContextState = {
  feed: {},
  error: {},
};
const NewsFeedContext = createContext(feedContextState);
function NewsFeedProvider({children}) {
  const {data, error} = useSWR('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@tacocattoken', fetcher);

  if (data !== undefined && data?.status === 'error') {
    feedContextState = {
      ...feedContextState,
      feed: data?.message,
    };
  } else if (data !== undefined && data?.status === 'ok') {
    feedContextState = {
      ...feedContextState,
      feed: data,
    };
  } else {
    feedContextState = {
      ...feedContextState,
      feed: error,
    };
  }
  return (
    <NewsFeedContext.Provider value={feedContextState}>
      {children}
    </NewsFeedContext.Provider>
  );
}

function useNewsFeed() {
  const context = useContext(NewsFeedContext);
  if (context === undefined) {
    throw new Error('useNewsFeed must be used within news provider');
  }
  return context;
}

export {
  NewsFeedProvider,
  useNewsFeed,
}
