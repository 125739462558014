import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./app.css";
import Navbar from "./components/navbar/Navbar";
import Landing from "./pages/Landing";
import Team from "./pages/Team";
import News from "./pages/News";
import Roadmap from "./pages/Roadmap";
import Minigame from "./pages/Minigame";
import Frogger from "./pages/Frogger";
import Charity from "./pages/Charity";
import HowToBuy from "./pages/HowToBuy";
import TermsAndConditions from "./pages/TermsOfService";
import FAQs from "./pages/FAQ";
import ScrollIntoView from "./lib/ScrollIntoView";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import "react-responsive-modal/styles.css";
import firebase from "firebase/app";
import "firebase/performance";
import "firebase/analytics";
import ReactGa from "react-ga";

import {library} from "@fortawesome/fontawesome-svg-core";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {NewsFeedProvider} from "./context/NewsFeedContext";

library.add(fab, fas);

// TODO: Extract and hide the following
const firebaseConfig = {
  apiKey: "AIzaSyDrYSUj_AvWX4UtFzJr3m5qHyo4C4cfFEI",
  authDomain: "tacocat-website.firebaseapp.com",
  projectId: "tacocat-website",
  storageBucket: "tacocat-website.appspot.com",
  messagingSenderId: "1013573890855",
  appId: "1:1013573890855:web:c31fdcb86870f7d1bd2f02",
  measurementId: "G-PSHNXNB9PV",
};

firebase.initializeApp(firebaseConfig);
firebase.performance();
firebase.analytics();

function App() {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState();
  const [isTablet, setIsTablet] = useState();
  const onCloseModal = () => setOpen(false);

  const setViewportWidth = () => {
    if (window.innerWidth <= 500) {
      setIsMobile(true);
    }
    if (window.innerWidth <= 700) {
      setIsTablet(true);
    }
    if (window.innerWidth >= 700) {
      setIsMobile(false);
      setIsTablet(false);
    }
  };

  useEffect(() => {
    setViewportWidth();

    ReactGa.initialize("UA-206246129-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <NewsFeedProvider>
      <Router>
        <ScrollIntoView>
          <Navbar />
          {open && (
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              focusTrapped={false}
              classNames={{
                overlay: "customOverlay",
                modal: "customModal",
              }}
              styles={{
                modal: {
                  textAlign: "center",
                  backgroundColor: "black",
                  borderRadius: "20px",
                },
              }}
            >
              <StyledHeader>
                V3 CONTRACT IS LIVE. New website coming soon!
              </StyledHeader>
              <a
                href="https://t.me/tacocattoken"
                target="_blank"
                style={{
                  color: "white",
                  fontFamily: "BrandonGrotesqueBlack",
                  fontSize: "1.9em",
                  marginBottom: "1em",
                }}
              >
                Click here
              </a>
              <span
                style={{
                  color: "white",
                  fontSize: "1.9em",
                  fontFamily: "BrandonGrotesqueBlack",
                }}
              >
                {" "}
                to find out more in our Telegram Community
              </span>
            </Modal>
          )}
          <Switch>
            <Route path="/" exact component={Landing}/>
            <Route path="/team" component={Team}/>
            <Route exact path="/news" component={News}/>
            <Route path="/roadmap" component={Roadmap}/>
            <Route path="/minigame" component={Minigame}/>
            <Route path="/minigame-frogger" component={Frogger}/>
            <Route path="/charity" component={Charity}/>
            <Route path="/faqs" component={FAQs}/>
            <Route path="/how-to-buy" component={HowToBuy}/>
            <Route path="/terms-of-service" component={TermsAndConditions}/>
          </Switch>
        </ScrollIntoView>
      </Router>
    </NewsFeedProvider>
  );
}
const StyledHeader = styled.h1`
  margin: 1em 2em 1em 2em;
  font-family: "BrandonGrotesqueBlack";
  color: #ffbd0c;
  font-size: 1.8em;
  line-height: 1.4em;
  text-transform: uppercase;
`;
export default App;
