import React from "react";
import "./roadmap.css";
import roadmap1 from "./images/roadmap1.png";
import roadmap2 from "./images/roadmap2.png";
import roadmap3 from "./images/roadmap3.png";

const Roadmap = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        backgroundColor: "#FBEBB9",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          backgroundColor: "#FBEBB9",
        }}
      >
        <img
          src={roadmap1}
          alt="roadmapPhase1"
          style={{ width: "80%", margin: "3% auto", borderRadius: "20px" }}
        />
        <img
          src={roadmap2}
          alt="roadmapPhase2"
          style={{ width: "80%", margin: "3% auto", borderRadius: "20px" }}
        />
        <img
          src={roadmap3}
          alt="roadmapPhase3"
          style={{
            width: "80%",
            marginTop: "3%",
            marginBottom: "8%",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "20px",
          }}
        />
      </div>
    </div>
  );
};

export default Roadmap;
