import React, { useState, useEffect } from "react";
import * as S from "./HowToBuy.styles";
import ReactPlayer from "react-player";
import Trust from "./images/Trust.png";
import TacoCat from "./images/tacocat.png";
import { ReactComponent as BinanceSVG } from "./images/binance.svg";
import { ReactComponent as TetherSVG } from "./images/tether.svg";
import { ReactComponent as PancakeSwapLogo } from "../sections/hero/images/pancakeswap-logo.svg";
import { ReactComponent as LBankLogo } from "../sections/hero/images/lbank-logo.svg";
import { ReactComponent as HotbitLogo } from "../sections/hero/images/hotbit-logo.svg";
import { ReactComponent as LATokenLogo } from "./images/latoken.svg";
import { ReactComponent as CoinsbitLogo } from "./images/coinsbit.svg";
import bitmartLogo from "./images/bitmart.png";

const HowToBuy = () => {
  const [isMobile, setIsMobile] = useState();
  const [isTablet, setIsTablet] = useState();

  const setViewportWidth = () => {
    if (window.innerWidth <= 500) {
      setIsMobile(true);
    }
    if (window.innerWidth <= 700) {
      setIsTablet(true);
    }
    setIsMobile(false);
  };

  useEffect(() => {
    setViewportWidth();
  }, []);

  return (
    <>
      <S.Hero>
        <S.Explainer>
          <S.ExplainerContainer>
            <S.Details>
              <S.Meet
                style={{
                  textShadow:
                    "-2px 0 #DC7F06, 0 2px #DC7F06, 2px 0 #DC7F06, 0 -2px #DC7F06",
                  color: "white",
                  letterSpacing: "2px",
                  ...(isTablet || isMobile ? { fontSize: "2em" } : null),
                }}
              >
                A WAY TO BUY FOR<br></br>
                <S.Letter style={{ color: "#ff1249" }}>E</S.Letter>
                <S.Letter style={{ color: "#DC7F06" }}>V</S.Letter>
                <S.Letter style={{ color: "#FFBD0C" }}>E</S.Letter>
                <S.Letter style={{ color: "#1EAA40" }}>R</S.Letter>
                <S.Letter style={{ color: "#C5E7FA" }}>Y</S.Letter>
                <S.Letter style={{ color: "indigo" }}>B</S.Letter>
                <S.Letter style={{ color: "violet" }}>O</S.Letter>
                <S.Letter style={{ color: "#DC7F06" }}>D</S.Letter>
                <S.Letter style={{ color: "#FFBD0C" }}>Y</S.Letter>
              </S.Meet>
              <p>
                TacoCat loves to teach! Check out his amazing animated tutorial
                video, where you'll be guided through buying TacoCat Token (TCT)
                with TrustWallet & PancakeSwap. Zero crypto experience needed.
                <br></br>
                <br></br>
                Are you a more seasoned crypto investor? Below you'll find some
                simple setup steps and a handy overview of all the platforms
                where TCT is currently available to buy.
                <br></br>
                <br></br>
                If you need any help, just reach out to us on{" "}
                <a
                  href="https://t.me/tacocattoken"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#DC7F06", textDecoration: "none" }}
                >
                  Telegram
                </a>
                .
              </p>
            </S.Details>

            {/* TODO: Maybe get this up with the custom no npm i option for border radius */}
            {/* TODO: Let's get this video up and sizing BEAUTIFULLY. Does it have to be an embed? */}
            <S.Video style={{ borderRadius: "20px" }}>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=fRQsd20d46Q"
                controls="true"
                // TODO: Might need the isMobile check here on mobile
                height={isMobile || isTablet ? 250 : 430}
                width={770}
              />
            </S.Video>
          </S.ExplainerContainer>
        </S.Explainer>
      </S.Hero>

      {/* TODO: 1. TRUSTWALLET SECTION */}
      <S.TrustWalletSection>
        <S.Container>
          <S.StepContainer>
            <S.Number
              style={{
                color: "white",
                // width: "500px",
                zIndex: 3,
                textShadow:
                  "-2px 0 #DC7F06, 0 2px #DC7F06, 2px 0 #DC7F06, 0 -2px #DC7F06",
              }}
            >
              1
            </S.Number>
            {/* <S.StepBreak></S.StepBreak> */}
            <S.StepDescription>
              <S.Meet
                style={{
                  color: "white",
                  textShadow:
                    "-1px 0 #DC7F06, 0 1px #DC7F06, 1px 0 #DC7F06, 0 -1px #DC7F06",
                }}
              >
                USE TRUSTWALLET
              </S.Meet>
              <p>
                TrustWallet is the official wallet of the Binance Exchange and
                is TacoCat's recommended wallet. Secure, easy-to-use and is the
                perfect place for storing your precious TCT.
              </p>
              <S.ButtonWrapper
                href="https://trustwallet.com/download-page"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.Button>
                  <S.ButtonCTA>DOWNLOAD</S.ButtonCTA>
                </S.Button>
              </S.ButtonWrapper>
            </S.StepDescription>
          </S.StepContainer>
          {/* TODO: Watch out on mobile for horizontal scrolling with the 400px image width*/}
          <S.Break></S.Break>
          <S.Image>
            <img
              src={Trust}
              height={isMobile || isTablet ? 300 : 400}
              width={isMobile || isTablet ? 300 : 400}
            />
          </S.Image>
        </S.Container>
      </S.TrustWalletSection>

      {/* TODO: 2. SEND BNB SECTION */}
      <S.SendBNBSection>
        <S.Container>
          <S.StepContainer>
            <S.Number style={{ color: "#ffdc5e" }}>2</S.Number>
            <S.StepDescription>
              <S.Meet
                style={{
                  color: "#ffbd0c",
                  textShadow:
                    "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white",
                }}
              >
                Buy BNB / USDT
              </S.Meet>
              <p style={{ color: "white" }}>
                These are what you'll use to buy TCT. Use BNB or USDT to buy on
                PancakeSwap and USDT for all our other platforms. Both
                currencies can be purchased directly from the TrustWallet app or
                on other exchanges, such as Binance.
              </p>
            </S.StepDescription>
          </S.StepContainer>
          <S.Break></S.Break>
          <S.Image
            style={{ height: "400px", width: "400px", position: "relative" }}
          >
            {/* <img src={TetherLogo} height={400} width={400} /> */}
            <BinanceSVG
              style={{
                position: "absolute",
                left: "25%",
                zIndex: "2",
                top: "8%",
              }}
              height={200}
              width={200}
            />
            <TetherSVG
              style={{
                position: "absolute",
                left: "25%",
                zIndex: "1",
                top: "44%",
              }}
              height={200}
              width={200}
            />
          </S.Image>
        </S.Container>
      </S.SendBNBSection>

      {/* TODO: 3. OPEN PANCAKESWAP */}
      <S.PancakeSwapSection>
        <S.Container>
          <S.StepContainer>
            <S.Number style={{ color: "#FFBD0C" }}>3</S.Number>
            <S.StepDescription>
              <S.Meet>Choose where to buy</S.Meet>
              <p>
                Now comes the fun part! TacoCat has made sure not to keep all
                his tacos in one basket, so you can now buy TCT on several
                platforms. Some of these offer special bonuses. For example,
                LBank gives you 27% APR. Whichever you choose, welcome to the
                TacoFamily!
              </p>
            </S.StepDescription>
          </S.StepContainer>
          <S.Break style={{ paddingBottom: 0 }}></S.Break>
          <S.Image
            style={{
              height: "400px",
              width: "400px",
              position: "relative",
              paddingBottom: "0",
            }}
          >
            <img
              style={{
                zIndex: "1",
                position: "relative",
                top: "25%",
                ...(isMobile || isTablet ? { left: "22%" } : { left: "25%" }),
              }}
              src={TacoCat}
              height={200}
              width={200}
              alt="tacocat"
            />
            <S.QuestionMark>?</S.QuestionMark>
          </S.Image>
        </S.Container>
      </S.PancakeSwapSection>

      {/* TODO: READY TO BUY? */}
      <S.ReadyToBuySection>
        <S.ReadyToBuyContainer>
          <S.Meet
            style={{ fontSize: "2.25em", margin: "0 auto", color: "white" }}
          >
            READY TO BUY?
          </S.Meet>

          <S.PlatformContainer>
            <S.Platform>
              <a
                href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2095d2346e47Ed497d4F39FcfA59918b4346cd65"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 auto" }}
              >
                <PancakeSwapLogo
                  style={{
                    height: "100px",
                    width: "100px",
                    ...(isTablet || isMobile
                      ? { height: "60px", width: "60px" }
                      : null),
                  }}
                />
              </a>
              <h3>PancakeSwap</h3>
            </S.Platform>
            <S.Platform>
              <a
                href="https://www.bitmart.com/trade/en?layout=basic&symbol=TCT_USDT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    ...(isTablet || isMobile
                      ? { height: "60px", width: "60px" }
                      : null),
                  }}
                  src={bitmartLogo}
                  alt="bitmartLogo"
                ></img>
              </a>
              <h3>Bitmart</h3>
            </S.Platform>
            <S.Platform>
              <a
                href="https://latoken.com/exchange/TCT_USDT"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 auto" }}
              >
                <LATokenLogo
                  style={{
                    height: "100px",
                    width: "100px",
                    ...(isTablet || isMobile
                      ? { height: "60px", width: "60px" }
                      : null),
                  }}
                />
              </a>
              <h3>LA Token</h3>
            </S.Platform>
            <S.Platform>
              <a
                href="https://www.lbank.info/exchange/tct/usdt"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LBankLogo
                  style={{
                    height: "100px",
                    width: "100px",
                    ...(isTablet || isMobile
                      ? { height: "60px", width: "60px" }
                      : null),
                  }}
                />
              </a>
              <h3>LBank</h3>
            </S.Platform>
            <S.Platform>
              <a
                href="https://www.hotbit.io/exchange?symbol=TCT_USDT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HotbitLogo
                  style={{
                    height: "100px",
                    width: "100px",
                    ...(isTablet || isMobile
                      ? { height: "60px", width: "60px" }
                      : null),
                  }}
                />
              </a>
              <h3>Hotbit</h3>
            </S.Platform>
            <S.Platform>
              <a
                href="https://coinsbit.io/trade_classic/TCT_USDT"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 auto" }}
              >
                <CoinsbitLogo
                  style={{
                    height: "100px",
                    width: "100px",
                    ...(isTablet || isMobile
                      ? { height: "60px", width: "60px" }
                      : null),
                  }}
                />
              </a>
              <h3>Coinsbit</h3>
            </S.Platform>
          </S.PlatformContainer>
        </S.ReadyToBuyContainer>
      </S.ReadyToBuySection>
    </>
  );
};

export default HowToBuy;
