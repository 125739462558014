import styled from "styled-components";

export const Section = styled.section`
  height: 100%;
  background-color: #713535;
  font-family: "ForgiaW01Outside";
  padding: 5% 0;

  @media (max-width: 1000px) {
    padding: 8% 0;
  }

  @media (max-width: 650px) {
    padding: 10% 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Title = styled.h1`
  display: block;
  color: #ffbd0c;
  font-size: 3em;
  margin: 0 auto;
  padding-bottom: 4%;

  @media (max-width: 1000px) {
    padding-bottom: 6%;
  }

  @media (max-width: 650px) {
    padding-bottom: 10%;
    padding-top: 5%;
    font-size: 2.5em;
    width: 80%;
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  line-height: 1.5em;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  text-shadow: -1px 1px 0 #ffffff, 1px 1px 0 #ffffff, 1px -1px 0 #ffffff,
    -1px -1px 0 #ffffff;

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 90%;
    flex-direction: column;
  }
`;

export const FeeCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8%;

  @media (max-width: 1000px) {
    margin: 0 3%;
  }
`;

export const FeeCardTitle = styled.h2`
  color: #dc7f06;
  margin: 0 auto;
  font-size: 2.2em;
  letter-spacing: 1.5px;
  padding-top: 10%;
  padding-bottom: 10%;
  line-height: 1em;

  @media (max-width: 650px) {
    padding-top: 5%;
    padding-bottom: 5%;
  }
`;

export const FeeCardCardDescription = styled.p`
  display: block;
  margin: 0 auto;
  font-family: "BrandonGrotesqueBlackLight";
  color: white;
  font-size: 1.2em;
  text-shadow: none;

  @media (max-width: 650px) {
    width: 60%;
    font-size: 1.2em;
    padding-bottom: 14%;
  }
`;

export const WhaleTaxCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8%;

  @media (max-width: 1000px) {
    margin: 0 3%;
  }
`;

export const WhaleTaxCardTitle = styled.h2`
  color: #62a000;
  margin: 0 auto;
  font-size: 2.2em;
  letter-spacing: 1.5px;
  padding-top: 10%;
  padding-bottom: 10%;
  line-height: 1em;

  @media (max-width: 650px) {
    padding-top: 5%;
    padding-bottom: 5%;
  }
`;

export const WhaleTaxCardDescription = styled.p`
  display: block;
  margin: 0 auto;
  font-family: "BrandonGrotesqueBlackLight";
  color: white;
  font-size: 1.2em;
  text-shadow: none;

  @media (max-width: 650px) {
    width: 60%;
    font-size: 1.2em;
    padding-bottom: 14%;
  }
`;

export const MultiSigCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8%;

  @media (max-width: 1000px) {
    margin: 0 3%;
  }
`;

export const MultiSigCardTitle = styled.h2`
  color: #ffbd0c;
  margin: 0 auto;
  font-size: 2.2em;
  letter-spacing: 1.5px;
  padding-top: 10%;
  padding-bottom: 10%;
  line-height: 1em;

  @media (max-width: 650px) {
    padding-top: 5%;
    padding-bottom: 5%;
  }
`;

export const MultiSigCardDescription = styled.p`
  display: block;
  margin: 0 auto;
  font-family: "BrandonGrotesqueBlackLight";
  color: white;
  font-size: 1.2em;
  text-shadow: none;

  @media (max-width: 650px) {
    width: 60%;
    font-size: 1.2em;
    padding-bottom: 5%;
  }
`;
