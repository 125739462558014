import React from "react";
import burrito from "./images/burrito.png";
import quesadilla from "./images/quesadilla.png";
import taco from "./images/taco.png";

import * as S from "./taconomics.styles";

export const Taconomics = () => {
  return (
    <S.Section>
      <S.Container>
        <S.Title>HOW THE TOKEN WORKS</S.Title>
        <S.CardContainer>
          <S.FeeCard>
            <img
              src={taco}
              style={{ height: "80px", width: "65px", margin: "0 auto" }}
              alt="taco"
            ></img>
            <S.FeeCardTitle>9% FEES</S.FeeCardTitle>
            <S.FeeCardCardDescription>
              3% goes to liquidity, 2% to a marketing wallet, and 4% gets
              distributed among all holders as a thank you.
            </S.FeeCardCardDescription>
          </S.FeeCard>
          <S.WhaleTaxCard>
            <img
              src={burrito}
              style={{ height: "80px", width: "65px", margin: "0 auto" }}
              alt="burrito"
            ></img>
            <S.WhaleTaxCardTitle>WHALE TAX</S.WhaleTaxCardTitle>
            <S.WhaleTaxCardDescription>
              Fees double for selling over 0.25% of the total supply. This
              discourages whales from trying to turn a quick profit.
            </S.WhaleTaxCardDescription>
          </S.WhaleTaxCard>
          <S.MultiSigCard>
            <img
              src={quesadilla}
              style={{ height: "80px", width: "65px", margin: "0 auto" }}
              alt="quesadilla"
            ></img>
            <S.MultiSigCardTitle>MULTI-SIG</S.MultiSigCardTitle>
            <S.MultiSigCardDescription>
              The contract is stored in a wallet with a multi-signature feature.
              If a change shall be made, both owners have to sign off.
            </S.MultiSigCardDescription>
          </S.MultiSigCard>
        </S.CardContainer>
      </S.Container>
    </S.Section>
  );
};
