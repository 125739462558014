import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import AnimatedNumber from "animated-number-react";

import coinsImage from "./images/hero-coins.png";
import { ReactComponent as BscScanLogo } from "./images/bscscan-logo-circle.svg";
import CMCLogo from "./images/coinmarketcap-logo.png";
import BlockFolioLogo from "./images/blockfolio-logo.jpeg";
import { ReactComponent as PancakeSwapLogo } from "./images/pancakeswap-logo.svg";
import { ReactComponent as LBankLogo } from "./images/lbank-logo.svg";
import { ReactComponent as HotbitLogo } from "./images/hotbit-logo.svg";
import { ReactComponent as LATokenLogo } from "../../how-to-buy/images/latoken.svg";

import coinsbitLogo from "./images/coinsbit-final.png";
import bitmartLogo from "../../how-to-buy/images/bitmart.png";

import coingeckoLogo from "./images/coingecko-logo.png";
import dextoolsLogo from "./images/dextools-logo.png";
import pdf from "../../../TacoCat-whitepaper.pdf";

import * as S from "./hero.styles";

const TOTAL_SUPPLY = 1000000000000;
const calculateCurrentMarketCap = (price) => price * TOTAL_SUPPLY;

export const Hero = () => {
  const [currentPrice, setCurrentPrice] = useState(0.0000011);
  const [currentMarketCap, setCurrentMarketCap] = useState(100000);
  // const [volume, setVolume] = useState(0);
  const [holderCount, setHolderCount] = useState(1000);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState();

  const formatValue = (value, decimal, prefix = true) =>
    `${prefix ? "$" : ""}${Number(value).toFixed(decimal)}`;

  const setViewportWidth = () => {
    if (window.innerWidth <= 650) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", setViewportWidth);

  useEffect(() => {
    setViewportWidth();

    // async function fetchData() {
      // const response = await fetch(
      //   "https://cors.bridged.cc/https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?id=10721",
      //   {
      //     method: "GET",
      //     headers: {
      //       "X-CMC_PRO_API_KEY": process.env.REACT_APP_CMC_KEY,
      //     },
      //     json: true,
      //     gzip: true,
      //     credentials: "same-origin",
      //   }
      // );

      // const priceResponse = await fetch(
      //   `https://cors.bridged.cc/https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0xb036f689bd98B4Bd3BD648FA09A23e54d839A859&page=1&offset=15000&apikey=${process.env.REACT_APP_BSC_SCAN_KEY}`,
      //   {
      //     method: "GET",
      //     json: true,
      //     gzip: true,
      //     credentials: "same-origin",
      //   }
      // );

      // const BSCResponse = await fetch(
      //   `https://cors.bridged.cc/https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=0xb036f689bd98B4Bd3BD648FA09A23e54d839A859&page=1&offset=15000&apikey=${process.env.REACT_APP_BSC_SCAN_KEY}`,
      //   {
      //     method: "GET",
      //     json: true,
      //     gzip: true,
      //     credentials: "same-origin",
      //   }
      // );

    //   const data = await response.json();
    //   const BSCData = await BSCResponse.json();

    //   const currentPrice = Number(data.data["10721"].quote.USD.price);
    //   // const volume = Number(data.data["10721"].quote.USD.volume_24h);
    //   const holderCount = Number(BSCData.result.length);

    //   setCurrentPrice(currentPrice);
    //   setCurrentMarketCap(calculateCurrentMarketCap(currentPrice));
    //   // setVolume(volume);
    //   setHolderCount(holderCount);
    //   setLoading(false);
    // }
    // fetchData();
  }, []);

  return (
    <S.Section>
      <S.Container>
        <S.InfoContainer>
          <S.TitleContainer>
            <S.Title>TACOCAT</S.Title>
            <S.Title>TOKEN.</S.Title>
          </S.TitleContainer>
          <S.Description>
            The first of its kind cryptocurrency token, TacoCat Token aims to
            bridge the gap between cryptocurrency and mainstream audiences.
          </S.Description>
          <S.ButtonWrapper href={pdf} target="_blank" rel="noopener noreferrer">
            <S.Button>
              <S.ButtonCTA>FIND OUT HOW</S.ButtonCTA>
            </S.Button>
          </S.ButtonWrapper>
          {/* <S.StatsContainer
            {...(isMobile && {
              style: {
                margin: "0 auto",
                paddingBottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            })}
          >
            <>
              <S.MarketCap>
                <S.MarketCapStat>
                  {loading ? (
                    <Skeleton style={{ borderRadius: "20px" }} />
                  ) : (
                    <AnimatedNumber
                      value={currentPrice}
                      duration={2000}
                      formatValue={(value) => formatValue(value, 9)}
                      delay={0}
                    />
                  )}
                </S.MarketCapStat>
                <S.MarketCapTitle>CURRENT PRICE</S.MarketCapTitle>
              </S.MarketCap>

              <S.Liquidity>
                <S.LiquidityStat>
                  {loading ? (
                    <Skeleton style={{ borderRadius: "20px" }} />
                  ) : (
                    <AnimatedNumber
                      value={currentMarketCap}
                      duration={2000}
                      formatValue={(value) =>
                        String(formatValue(value, 0)).replace(
                          /(.)(?=(\d{3})+$)/g,
                          "$1,"
                        )
                      }
                      delay={0}
                    />
                  )}
                </S.LiquidityStat>
                <S.LiquidityTitle>MARKET CAP</S.LiquidityTitle>
              </S.Liquidity>
            </>
          </S.StatsContainer>

          <S.StatsContainer {...(isMobile && { style: { marginTop: 0 } })}>
            <S.Liquidity>
              <S.LiquidityStat>
                {loading ? (
                  <Skeleton style={{ borderRadius: "20px" }} />
                ) : (
                  <AnimatedNumber
                    value={holderCount}
                    duration={2000}
                    formatValue={(value) =>
                      String(formatValue(value, 0, false)).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1,"
                      )
                    }
                    delay={0}
                  />
                )}
              </S.LiquidityStat>
              <S.LiquidityTitle>TOTAL HOLDERS</S.LiquidityTitle>
            </S.Liquidity>
          </S.StatsContainer> */}

          <S.WhereToBuy>Token Info</S.WhereToBuy>
          <S.LinksContainer>
            <S.CoinMarketCap>
              <div></div>
              <a
                // href="https://coinmarketcap.com/currencies/tacocat-token/"
                target="_blank"
                rel="noopener noreferrer"
                data-tip={isMobile ? "" : "CoinMarketCap"}
              >
                <img
                  style={{ height: "40px", width: "40px" }}
                  src={CMCLogo}
                  alt="coinmarketcapLogo"
                ></img>
              </a>
              {isMobile && (
                <S.MobileTitle style={{ left: "-30px" }}>
                  CoinMarketCap
                </S.MobileTitle>
              )}
              <ReactTooltip />
            </S.CoinMarketCap>
            <S.CoinGecko>
              {isMobile && (
                <S.MobileTitle style={{ left: "-15px" }}>
                  <span style={{ position: "absolute", bottom: 0 }}>
                    CoinGecko
                  </span>
                </S.MobileTitle>
              )}
              <a
                // href="https://www.coingecko.com/en/coins/tacocat-token"
                target="_blank"
                rel="noopener noreferrer"
                data-tip={isMobile ? "" : "CoinGecko"}
              >
                <img
                  style={{ height: "40px", width: "40px" }}
                  src={coingeckoLogo}
                  alt="coingeckoLogo"
                ></img>
              </a>
              <ReactTooltip />
            </S.CoinGecko>
            <S.DexTools>
              <div></div>
              <a
                href="https://blockfolio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100%",
                  }}
                  src={BlockFolioLogo}
                  alt="blockfolio-logo"
                  data-tip={isMobile ? "" : "Blockfolio"}
                ></img>
              </a>
              {isMobile && (
                <S.MobileTitle style={{ left: "-19px" }}>
                  BlockFolio
                </S.MobileTitle>
              )}
            </S.DexTools>
            <S.BSCScan>
              {isMobile && (
                <S.MobileTitle style={{ left: "-8px" }}>
                  <span style={{ position: "absolute", bottom: 0 }}>
                    BSCScan
                  </span>
                </S.MobileTitle>
              )}
              <a
                href="https://bscscan.com/token/0xb036f689bd98B4Bd3BD648FA09A23e54d839A859"
                target="_blank"
                rel="noopener noreferrer"
                data-tip={isMobile ? "" : "BSCScan"}
              >
                <BscScanLogo style={{ height: "40px", width: "40px" }} />
              </a>
              <div></div>

              <ReactTooltip />
            </S.BSCScan>
            <S.DexTools>
              <div></div>
              <a
                href="https://www.dextools.io/app/pancakeswap/pair-explorer/0xb036f689bd98B4Bd3BD648FA09A23e54d839A859"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ height: "40px", width: "40px" }}
                  src={dextoolsLogo}
                  alt="dextoolsLogo"
                  data-tip={isMobile ? "" : "Dextools"}
                ></img>
              </a>
              {isMobile && (
                <S.MobileTitle style={{ left: "-10px" }}>
                  DexTools
                </S.MobileTitle>
              )}
            </S.DexTools>
            <ReactTooltip />
          </S.LinksContainer>

          <S.WhereToBuyContainer>
            <S.WhereToBuy>Where to Buy</S.WhereToBuy>
            <S.PlatformContainer>
              <S.Platform>
                <div></div>
                <a
                  href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb036f689bd98B4Bd3BD648FA09A23e54d839A859"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={isMobile ? "" : "PancakeSwap"}
                >
                  <PancakeSwapLogo style={{ height: "40px", width: "40px" }} />
                </a>
                {isMobile && (
                  <S.MobileTitle style={{ left: "-32px" }}>
                    PancakeSwap
                  </S.MobileTitle>
                )}
                <ReactTooltip />
              </S.Platform>

              <S.Platform>
                {isMobile && (
                  <S.MobileTitle style={{ left: "-10px" }}>
                    <span style={{ position: "absolute", bottom: 0 }}>
                      Bitmart
                    </span>
                  </S.MobileTitle>
                )}
                <a
                  // href="https://www.bitmart.com/trade/en?layout=basic&symbol=TCT_USDT"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={isMobile ? "" : "Bitmart"}
                >
                  <img
                    style={{ height: "40px", width: "40px" }}
                    src={bitmartLogo}
                    alt="bitmartLogo"
                  ></img>
                </a>
                <div></div>
                <ReactTooltip />
              </S.Platform>

              <S.Platform>
                <div></div>
                <a
                  // href="https://latoken.com/exchange/TCT_USDT"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={isMobile ? "" : "LAToken"}
                >
                  <LATokenLogo style={{ height: "40px", width: "40px" }} />
                </a>
                {isMobile && (
                  <S.MobileTitle style={{ left: "-13px" }}>
                    LAToken
                  </S.MobileTitle>
                )}
                <ReactTooltip />
              </S.Platform>

              <S.Break></S.Break>

              <S.Platform>
                {isMobile && (
                  <S.MobileTitle style={{ left: "-3px" }}>
                    <span style={{ position: "absolute", bottom: 0 }}>
                      LBank
                    </span>
                  </S.MobileTitle>
                )}
                <a
                  // href="https://www.lbank.info/exchange/tct/usdt"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={isMobile ? "" : "LBank"}
                >
                  <LBankLogo style={{ height: "41px", width: "41px" }} />
                </a>
                <div></div>
                <ReactTooltip />
              </S.Platform>

              <S.Platform>
                <div></div>
                <a
                  // href="https://www.hotbit.io/exchange?symbol=TCT_USDT"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={isMobile ? "" : "HotBit"}
                >
                  <HotbitLogo style={{ height: "40px", width: "40px" }} />
                </a>
                {isMobile && (
                  <S.MobileTitle style={{ left: "-5px" }}>HotBit</S.MobileTitle>
                )}
                <ReactTooltip />
              </S.Platform>

              <S.Platform>
                {isMobile && (
                  <S.MobileTitle style={{ left: "-12px" }}>
                    <span style={{ position: "absolute", bottom: 0 }}>
                      Coinsbit
                    </span>
                  </S.MobileTitle>
                )}
                <a
                  // href="https://coinsbit.io/trade_classic/TCT_USDT"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tip={isMobile ? "" : "Coinsbit"}
                >
                  <img
                    style={{ height: "40px", width: "40px" }}
                    src={coinsbitLogo}
                    alt="coinsbitLogo"
                  ></img>
                </a>
                <div></div>
                <ReactTooltip />
              </S.Platform>
            </S.PlatformContainer>
          </S.WhereToBuyContainer>
        </S.InfoContainer>

        <S.CoinsCointainer>
          <S.CoinsImage src={coinsImage} />
        </S.CoinsCointainer>
      </S.Container>
    </S.Section>
  );
};
